// LINK_ACTIONS
export const GET_LINK_ANALYTICS = 'GET_LINK_ANALYTICS';
export const RESET_CURRENT_LINK = 'RESET_CURRENT_LINK';
export const CREATE_LINK = 'CREATE_LINK';
export const OPEN_LINK = 'OPEN_LINK';
export const UPLOAD_PDF = 'UPLOAD_PDF';
export const AUTH = "AUTH";

// SNACKBAR_ACTIONS
export const DISPLAY_SNACKBAR = 'DISPLAY_SNACKBAR';
export const HANDLE_CLOSE = 'HANDLE_CLOSE';

// ERROR_ACTIONS
export const RESET_ERRORS = 'RESET_ERRORS';
