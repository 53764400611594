import React from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';

import AppToolBar from './components/AppToolBar';
import Home from './views/Home';
import Analytics from './views/Analytics';
import Upload from './views/Upload';
import General from './views/General';
import PdfViewer from './views/PdfViewer';
import Auth from './views/Auth';

export default () => (
  <BrowserRouter>
    <AppToolBar />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/analytics" exact component={Analytics} />
      <Route path="/upload" exact component={Upload} />
      <Route path="/pdfviewer" exact component={PdfViewer} />
      <Route path="/auth" exact component={Auth} />
      <Route path="/*" exact component={General} />
    </Switch>
  </BrowserRouter>
);
