import React from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { displaySnackbar } from '../actions/snackbarActions';
import {
  Button,
  Grid,
  Container,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box
} from '@material-ui/core';
import { auth } from '../actions/linkActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  heroContent: {
    padding: theme.spacing(2, 0, 9),
  },
  shortenButton: {
    width: '100%',
    lineHeight: 3.2,
    backgroundColor: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  browsePDF: {
    height: '40px'
  }
});

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: '',
      email_addr: '',
      client_idError: '',
      email_addrError: ''
    };
  }  

  componentDidMount() {
    
  }

  handleClose = () => {
    this.setState({open: false});
  };
  handleToggle = () => {
    this.setState({open: !this.state.open});
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }  

  onSubmit = (e) => {    
    e.preventDefault();
    const errors = [];
    let { email_addr, client_id, email_addrError, client_idError } = this.state;    
    if (email_addr.length === 0) {
      email_addrError = 'Cannot be empty';
    }else{
      email_addrError = '';
    }
    if (client_id.length === 0) {
      client_idError = 'Cannot be empty';
    }else{
      client_idError = '';
    }
    if(!email_addrError && !(new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g).test(email_addr))){
      email_addrError = 'Email is invalid format'
    }
    
    if(!email_addrError && !client_idError) {
      this.handleToggle();
      this.props.auth({email_addr: email_addr, client_id: client_id});  
    }
    this.setState({ client_idError: client_idError, email_addrError: email_addrError });
  }

  render() {
    //Redirect to upload pdf page if authened
    const isLogined = localStorage.getItem('isLogined');
    if(isLogined)
      this.props.history.push('upload');

    const { classes, displaySnackbar, loginedResult } = this.props;
    const { client_idError, open, email_addrError, client_id, email_addr } = this.state;   
    let isOpen = open;       
    
    if(loginedResult && loginedResult.status){
      isOpen = false;
      if(loginedResult.status == 'OK' && loginedResult.data){
        localStorage.setItem('isLogined', true);
        localStorage.setItem('access_token', loginedResult.data.access_token);
        this.props.history.push('upload');
      }
    }

    return (
      <>
        <CssBaseline />
        <main>
          <div className={classes.heroContent}>
            <Container maxWidth="md">
              <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              LOGIN TO UPLOAD PDF
              </Typography>
              <div className={classes.heroButtons}>
                <form onSubmit={this.onSubmit}>
                  <Grid container spacing={2} justify="center">
                  <Grid item xs={12} style={{textAlign: "center", color: "red"}}>
                      {loginedResult ? loginedResult.msg : ''}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="client_id"
                        type="text"
                        label="Client ID"
                        name="client_id"
                        value={client_id}
                        onChange={this.onChange}
                        variant="outlined"
                        fullWidth
                        error={client_idError}
                        helperText={(client_idError) ? client_idError : ''}
                        style={{ backgroundColor: 'white' }}                        
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="email_addr"
                        type="text"
                        label="Email address"
                        name="email_addr"
                        value={email_addr}
                        onChange={this.onChange}
                        variant="outlined"
                        fullWidth
                        error={email_addrError}
                        helperText={(email_addrError) ? email_addrError : ''}
                        style={{ backgroundColor: 'white' }}                        
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.shortenButton}
                        onClick={this.onSubmit}
                      >
                        LOGIN
                      </Button>
                    </Grid>                    
                  </Grid>
                </form>
              </div>
              <div>              
                <Backdrop className={classes.backdrop} open={isOpen}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Container>            
          </div>
        </main>
      </>
    );
  }
}

Auth.propTypes = { 
  loginedResult: Proptypes.func.isRequired,  
  displaySnackbar: Proptypes.func.isRequired,  
  createdLinks: Proptypes.array,
  resetCurrentLink: Proptypes.func.isRequired,
};

Auth.defaultProps = {
  loginedResult: {},  
};

const mapStateToProps = (state) => ({
  loginedResult: state.links.loginedResult,  
});

const mapDispatchToProps = (dispatch) => ({
  auth: (data) => dispatch(auth(data)),  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Auth));
