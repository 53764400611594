import axios from 'axios';

import {
  GET_LINK_ANALYTICS,
  RESET_CURRENT_LINK,
  CREATE_LINK,
  DISPLAY_SNACKBAR,
  OPEN_LINK,
  UPLOAD_PDF,
  AUTH,
} from '../constants/actionTypes';

const env = 'production';
const config = require('../config/config')[env];

export const getLinkAnalytics = (tinyUrlId) => async (dispatch) => {
  try {
    const response = await axios.get(`${config.API_URL}/links/analytics/${tinyUrlId}`);
    dispatch({
      type: GET_LINK_ANALYTICS,
      payload: response.data,
    });
  } catch (err) {
    const snackbarPayload = {
      variant: 'error',
      msg: err.response && err.response.data && err.response.data.message? err.response.data.message : '',
    };
    dispatch({
      type: DISPLAY_SNACKBAR,
      payload: snackbarPayload,
    });
    dispatch({
      type: 'GET_LINK_ANALYTICS_ERROR',
      error: err.response && err.response.data ? err.response.data : '',
    });
  }
};

export const resetCurrentLink = () => async (dispatch) => {
  dispatch({ type: RESET_CURRENT_LINK });
};

export const createLink = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${config.API_URL}`, data);
    dispatch({
      type: CREATE_LINK,
      payload: response.data,
    });
  } catch (err) {
    const snackbarPayload = {
      variant: 'error',
      msg: err.response && err.response.data && err.response.data.message? err.response.data.message : '',
    };
    dispatch({
      type: DISPLAY_SNACKBAR,
      payload: snackbarPayload,
    });    
    dispatch({
      type: 'CREATE_LINK_ERROR',
      error: err.response && err.response.data ? err.response.data : '',
    });
  }
};

export const openLink = (tinyUrId) => async (dispatch) => {
  try {
    const response = await axios.get(`${config.API_URL}/t/${tinyUrId}`);
    dispatch({
      type: OPEN_LINK,
      payload: response.data,
    });
  } catch (err) {    
    const snackbarPayload = {
      variant: 'error',
      msg: err.response && err.response.data && err.response.data.message? err.response.data.message : '',
    };
    dispatch({
      type: DISPLAY_SNACKBAR,
      payload: snackbarPayload,
    });    
    dispatch({
      type: 'OPEN_LINK_ERROR',
      error: err.response && err.response.data ? err.response.data : '',
    });
  }
};

export const uploadPdf = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${config.API_URL}/upload`, data);
    dispatch({
      type: UPLOAD_PDF,
      payload: response.data,
    });
  } catch (err) {   
    console.log(err); 
    const snackbarPayload = {
      variant: 'error',
      msg: err.response && err.response.data && err.response.data.message? err.response.data.message : '',
    };
    dispatch({
      type: DISPLAY_SNACKBAR,
      payload: snackbarPayload,
    });    
    dispatch({
      type: 'UPLOAD_PDF_ERROR',
      error: err.response && err.response.data ? err.response.data : '',
    });
  }
};

export const auth = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${config.API_BLASTSEND_URL}/auth/token`, data);
    dispatch({
      type: AUTH,
      payload: response.data,
    });
  } catch (err) {   
    console.log(err); 
    const snackbarPayload = {
      variant: 'error',
      msg: err.response && err.response.data && err.response.data.message? err.response.data.message : '',
    };
    dispatch({
      type: DISPLAY_SNACKBAR,
      payload: snackbarPayload,
    });    
    dispatch({
      type: 'AUTH_ERROR',
      error: err.response && err.response.data ? err.response.data : '',
    });
  }
};
