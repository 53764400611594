import {
  GET_LINK_ANALYTICS,
  RESET_CURRENT_LINK,
  CREATE_LINK,
  OPEN_LINK,
  UPLOAD_PDF,
  AUTH,
} from '../constants/actionTypes';

const initialState = {
  currentLink: {},
  createdLinks: [],
  originalLink: {},
  uploadedLink: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LINK_ANALYTICS:
      return {
        ...state,
        currentLink: action.payload,
      };
    case RESET_CURRENT_LINK:
      return {
        ...state,
        currentLink: {},
        createdLinks: [],
        originalLink: {},
        uploadedLink: {}
      };
    case CREATE_LINK:
      return {
        ...state,
        createdLinks: [action.payload, ...state.createdLinks],
      };
    case OPEN_LINK:
        return {
          ...state,
          originalLink: action.payload,
        };
    case UPLOAD_PDF:
          return {
            ...state,
            uploadedLink: action.payload,
          };
    case AUTH:
      return {
        ...state,
        loginedResult: action.payload,
      };
    default:
      return state;
  }
};
