import React from 'react';
import Router from '../Router';
import '../App.css';
import AppSnackBar from './AppSnackbar';

const App = () => (
  <div className="App">
    <AppSnackBar />
    <Router />
  </div>
);

export default App;
