import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Icon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textDecoration: 'none',
    color: 'white',
  },
}));

const AppToolBar = () => {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoPage = (_page) => {
    setAnchorEl(null);
    history.push(_page);    
  }

  const logout = () => {
    localStorage.setItem('isLogined', '');
    localStorage.setItem('access_token', '');
    //history.push('/');
    window.location.href = '/';
  }
  
  /**
   * Hide toolbar for page = general
   */
  let hideToolbar = false;
  if(history && history.location && history.location.pathname.indexOf('/t/') > -1)
    hideToolbar = true;
  
  const isLogined = localStorage.getItem('isLogined');

  return (
    <>
      {!hideToolbar && 
      <AppBar position="static" color="primary" elevation={0} className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="h5" color="inherit" noWrap className={classes.toolbarTitle}>
            <Link to="/" className={classes.link}>
              Triming URL
            </Link>
          </Typography>
          <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <Icon style={{ color: '#ffffff' }}>menu</Icon>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => gotoPage('analytics')}>Analytics</MenuItem>
              <MenuItem onClick={() => gotoPage('upload')}>Upload PDF</MenuItem> 
              { isLogined &&
              <MenuItem onClick={() => logout()}>Logout</MenuItem>           
              }
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      }
    </>
  );
};

export default AppToolBar;
