module.exports = {
  development: {
    API_URL: 'http://localhost:5000',
    BASE_URL: 'http://localhost:3000'
  },
  test: {
  },
  production: {
    API_URL: 'https://api.gettr.im',
    BASE_URL: 'https://gettr.im',
    API_BLASTSEND_URL: 'https://api.blastsend.com'
  },
};
