import React from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { openLink } from '../actions/linkActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const env = 'production';
const config = require('../config/config')[env];

class General extends React.Component {  
    UNSAFE_componentWillMount() {
        const {location} = this.props;        
        if(location && location.pathname){            
            let tinyUrlId = location.pathname.substr(location.pathname.indexOf(config.BASE_URL + '/t/') + 4);

            //Delete last '/'
            if(tinyUrlId.lastIndexOf('/') == tinyUrlId.length - 1)
              tinyUrlId = tinyUrlId.substr(0, tinyUrlId.length - 1);
            
            //Get original link
            if(tinyUrlId){
                this.props.openLink(tinyUrlId);
            }
        }
        //const defaultLayoutPluginInstance = defaultLayoutPlugin();
    }  
    
    render() {
        const { originalLink } = this.props;
        let open = true;          
        
        if(originalLink && originalLink.location){
            if(originalLink.location.indexOf('.pdf') < 0) //Redirect to original link
              window.location.href = originalLink.location;
            else{//Display pdf content                     
              open = false;
              localStorage.setItem('pdfFileUrl', originalLink.location);
              this.props.history.push('../../pdfviewer');
            }
        }
        
        return (
          <div>              
            <Backdrop open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>            
          </div>
        );
    }
}

General.propTypes = { 
    originalLink: Proptypes.func.isRequired,    
  };
  
  General.defaultProps = {
    originalLink: {},
  };
  
  const mapStateToProps = (state) => ({
    originalLink: state.links.originalLink,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    openLink: (tinyUrlId) => dispatch(openLink(tinyUrlId))    
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(General);