import React from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { displaySnackbar } from '../actions/snackbarActions';
import {
  Button,
  Grid,
  Container,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box
} from '@material-ui/core';
import { uploadPdf, createLink, resetCurrentLink } from '../actions/linkActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  heroContent: {
    padding: theme.spacing(2, 0, 9),
  },
  shortenButton: {
    width: '100%',
    lineHeight: 3.2,
    backgroundColor: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  browsePDF: {
    height: '40px'
  }
});

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      protectedCode: '',
      protectedCodeError: '',
      contentsError: '',
      contents: '',
      open: false,
      uploadFileName: '',
      already_created_link: false
    };
  }  

  componentDidMount() {
    this.props.resetCurrentLink(); 
  }

  handleClose = () => {
    this.setState({open: false});
  };
  handleToggle = () => {
    this.setState({open: !this.state.open});
  };

  handleFile = e => {
    console.log(e.target);
    this.setState({ 
      ...this.state, 
      [e.target.name]: e.target.files[0],
      uploadFileName: e.target.files[0].name
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
  }

  onSubmit = (e) => {    
    e.preventDefault();
    const errors = [];
    let { protectedCode, contents, contentsError, protectedCodeError } = this.state;    
    if (contents.length === 0) {
      contentsError = 'Cannot be empty';
    }else{
      contentsError = '';
    }
    if (protectedCode.length === 0) {
      protectedCodeError = 'Cannot be empty';
    }else{
      protectedCodeError = '';
    }
    
    if(!protectedCodeError && !contentsError) {
      this.handleToggle();
      this.getBase64(contents, (result) => {
        result = result ? result.replace('data:application/pdf;base64,', '') : '';        
        this.props.uploadPdf({contents: result, protected_code: protectedCode, access_token: localStorage.getItem('access_token')}); 
      }); 
    }
    this.setState({ contentsError: contentsError, protectedCodeError: protectedCodeError });
  }

  render() {
    const { uploadedLink, classes, displaySnackbar } = this.props;
    const { protectedCode, open, protectedCodeError, uploadFileName } = this.state;   
    let isOpen = open; 
    let short_id = '';

    //Redirect to login page if not authen
    const isLogined = localStorage.getItem('isLogined');
    if(!isLogined)
      this.props.history.push('auth');

    if(uploadedLink &&  uploadedLink.file_name){
      isOpen = false;      
      short_id = uploadedLink.s_url || '';
    }   

    return (
      <>
        <CssBaseline />
        <main>
          <div className={classes.heroContent}>
            <Container maxWidth="md">
              <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              Upload PDF
              </Typography>              
              <div className={classes.heroButtons}>
                <form onSubmit={this.onSubmit}>
                  <Grid container spacing={2} justify="center">                    
                    <Grid item xs={12}>
                      <TextField
                        id="protectedCode"
                        type="password"
                        label="Protected Code"
                        name="protectedCode"
                        value={protectedCode}
                        onChange={this.onChange}
                        variant="outlined"
                        fullWidth
                        error={protectedCodeError}
                        helperText={(protectedCodeError) ? protectedCodeError : ''}
                        style={{ backgroundColor: 'white' }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor="contents">
                          <input
                            accept="application/pdf"
                            id="contents"
                            name="contents"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.handleFile} />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span" >
                            Choose Files
                          </Button>
                          <span> {uploadFileName}</span>
                      </label>                      
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.shortenButton}
                        onClick={this.onSubmit}
                      >
                        Upload
                      </Button>
                    </Grid>
                    {short_id && (
                    <Grid xs={12}>
                      <List dense style={{ border: '1px solid #b2b8c3', borderRadius: '5px' }}>
                        <ListItem>
                          <ListItemText
                            primary={short_id}
                          />
                          <ListItemSecondaryAction>
                            <CopyToClipboard
                              text={short_id}
                              onCopy={() => displaySnackbar({ msg: 'Copied to clipboard!' })}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                              >
                              Copy
                              </Button>
                            </CopyToClipboard>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>                      
                    </Grid>
                    )}
                  </Grid>
                </form>
              </div>
              <div>              
                <Backdrop className={classes.backdrop} open={isOpen}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </Container>            
          </div>
        </main>
      </>
    );
  }
}

Upload.propTypes = { 
  uploadedLink: Proptypes.func.isRequired,  
  displaySnackbar: Proptypes.func.isRequired,  
  createdLinks: Proptypes.array,
  resetCurrentLink: Proptypes.func.isRequired,
};

Upload.defaultProps = {
  uploadedLink: {},  
};

const mapStateToProps = (state) => ({
  uploadedLink: state.links.uploadedLink,  
});

const mapDispatchToProps = (dispatch) => ({
  uploadPdf: (data) => dispatch(uploadPdf(data)),
  displaySnackbar: (data) => dispatch(displaySnackbar(data)),  
  resetCurrentLink: () => dispatch(resetCurrentLink()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Upload));
